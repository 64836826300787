<template>
	<div>
		<div class="text-center">
			<Search

				:search="item_search"
				:option="search_option"

				@reset="reset"
				@click="getData"
				@toExcel="toExcel"
				class="mt-10"
			>
				<button
					slot="add"
					class="btn-white pa-5-10  ml-10"
					@click="setMonth(0)"
				>지난달</button>
				<button
					slot="add"
					class="btn-white pa-5-10  ml-10"
					@click="setMonth(1)"
				>2달전</button>
			</Search>
		</div>
		<div class="mt-20  bg-white pa-10">
			<div><h6 class="font-weight-bold">포인트 사용 통계</h6></div>
			<table class="table mt-15">
				<colgroup>

				</colgroup>
				<thead>
					<tr>
						<th>이월 포인트</th>
						<th>충전 포인트</th>
						<th>사용 포인트</th>
						<th>차감 포인트</th>
						<th>포인트 잔액</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ item_use.old_point | makeComma}}P</td>
						<td>{{ item_use.charge_point | makeComma}}P</td>
						<td>{{ item_use.use_point | makeComma}}P</td>
						<td>{{ item_use.minus_point | makeComma}}P</td>
						<td>{{ item_use.point | makeComma}}P</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="mt-20 bg-white pa-10">
			<div><h6 class="font-weight-bold">포인트 충전 통계</h6></div>
			<table class="table mt-15">
				<colgroup>

				</colgroup>
				<thead>
					<tr>
						<th>구분</th>
						<th>신용카드</th>
						<th>휴대폰 소액결제</th>
						<th>관리자 차감</th>
						<th>합계</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>충전 건수</th>
						<td>{{ item_charge.card_count | makeComma}}건</td>
						<td>{{ item_charge.phone_count | makeComma}}건</td>
						<td>{{ item_use.minus_count | makeComma}}건</td>
						<td>{{ item_charge.total_count | makeComma}}건</td>
					</tr>
					<tr>
						<th>결제 금액</th>
						<td>{{ item_charge.card_amount | makeComma}}원</td>
						<td>{{ item_charge.phone_amount | makeComma}}원</td>
						<td>{{ item_use.minus_amount | makeComma}}원</td>
						<td>{{ item_charge.total_amount | makeComma}}원</td>
					</tr>
					<tr>
						<th>충전 포인트</th>
						<td>{{ item_charge.card_point | makeComma}}P</td>
						<td>{{ item_charge.phone_point | makeComma}}P</td>
						<td>{{ item_use.minus_point | makeComma}}P</td>
						<td>{{ item_charge.total_point | makeComma}}P</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="mt-20 bg-white pa-10">
			<div><h6 class="font-weight-bold">쇼핑몰 결제 통계</h6></div>
			<table class="table mt-15">
				<colgroup>

				</colgroup>
				<thead>
					<tr>
						<th>구분</th>
						<th>상품권몰</th>
						<th>일반몰</th>
						<th>기업몰</th>
						<th colspan="2">합계</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>결제 수단</th>
						<td>신용카드</td>
						<td>신용카드</td>
						<td>포인트</td>
						<td>신용카드</td>
						<td>포인트</td>
					</tr>
					<tr>
						<th>결제건수</th>
						<td>{{ item_order.ticket_count | makeComma}}건</td>
						<td>{{ item_order.normal_count | makeComma}}건</td>
						<td>{{ item_order.business_count | makeComma}}건</td>
						<td>{{ item_order.card_order_count | makeComma}}건</td>
						<td>{{ item_order.point_order_count | makeComma}}건</td>
					</tr>
					<tr>
						<th>주문금액</th>
						<td>{{ item_order.ticket_amount | makeComma}}원</td>
						<td>{{ item_order.normal_amount | makeComma}}원</td>
						<td>{{ item_order.business_amount | makeComma}}원</td>
						<td>{{ item_order.card_order_amount | makeComma}}원</td>
						<td>{{ item_order.point_order_amount | makeComma}}원</td>
					</tr>
				</tbody>
			</table>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>
	import Search from "@/view/Layout/Search";
	import Excel from "@/components/Excel";
	export default {
		name: 'StatisticsList'
		, components: {Excel, Search}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '월별 통계'
					, top: true
					, title: true
					, bottom: false
				}
				, items: []
				, item_use: {

				}
				, item_charge: {

				}
				, item_order: {

				}
				, item_search: {
					sDate: this.$date.getMonthlyDate('', '-').start
					, eDate: this.$date.getToday('-')
				}
				, search_option: {
					is_excel: true
					,sDate: true
					,eDate: true
					, not_search: true
					, search_limit: 90
					, is_reset: true
				}
				,is_excel: false
				,excel_data: {
					name: '통계'
					, header_top: [
						{ key: 0, name: '포인트 사용통계', col: '4'}
						, { key: 0, name: '포인트 충전 통계', col: '9'}
						, { key: 0, name: '쇼핑몰 결제 통계', col: '6'}
					]
					,header: [
						{ key: 0, name: '일자', column: 'sDate'}
						,{ key: 0, name: '충전 포인트', column: 'charge_point'}
						,{ key: 0, name: '사용 포인트', column: 'use_point'}
						,{ key: 0, name: '포인트 잔액', column: 'point'}

						,{ key: 0, name: '신용카드 충전 건수', column: 'card_count'}
						,{ key: 0, name: '신용카드 결제 금액', column: 'card_amount'}
						,{ key: 0, name: '신용카드 충전 포인트', column: 'card_point'}

						,{ key: 0, name: '휴대폰 소액 충전 건수', column: 'phone_count'}
						,{ key: 0, name: '휴대폰 소액 결제 금액', column: 'phone_amount'}
						,{ key: 0, name: '휴대폰 소액 충전 포인트', column: 'phone_point'}

						,{ key: 0, name: '합계 충전 건수', column: 'total_count'}
						,{ key: 0, name: '합계 결제 금액', column: 'total_amount'}
						,{ key: 0, name: '합계 충전 포인트', column: 'total_point'}

						,{ key: 0, name: '상품권몰 신용카드 결제 건수', column: 'ticket_count'}
						,{ key: 0, name: '상품권몰 신용카드 주문 금액', column: 'ticket_amount'}

						,{ key: 0, name: '일반몰 신용카드 결제 건수', column: 'normal_count'}
						,{ key: 0, name: '일반몰 신용카드 주문 금액', column: 'normal_amount'}

						,{ key: 0, name: '기업몰 포인트 결제 건수', column: 'business_count'}
						,{ key: 0, name: '기업몰 포인트 주문 금액', column: 'business_amount'}
					]
					,content: null
				}
			}
		}
		, computed: {
			items_list: function(){
				return this.items.filter((item) => {
					item.total_count = Number(item.card_count) + Number(item.phone_count)
					item.total_amount = Number(item.card_amount) + Number(item.phone_amount)
					item.total_point = Number(item.card_point) + Number(item.phone_point)

					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{

					let t = this.$date.getPeriod(this.item_search.eDate, this.item_search.sDate)
					if(this.search_option.search_limit < t){
						throw `조회기간은 ${this.search_option.search_limit}일을 초과할 수 없습니다.`
					}
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'get'
						,url: 'management/getStatisticsMonth'
						,data: {
							sDate: this.item_search.sDate
							, eDate: this.item_search.eDate
						}
					})
					if(result.success){
						this.items = result.data.result
						this.item_use = result.data.use_result
						this.item_charge = result.data.charge_result
						this.item_order = result.data.order_result
					}else{
						throw result.message
					}
				}catch(e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setDate: function(date){
				this.item_search.sDate = date
				this.$storage.push({ name: this.$route.name, params: { date: date}})
			}
			, toExcel: function(){

				this.is_excel = true
				this.excel_data.content = this.items_list
			}
			, reset: function() {
				this.item_search = {
					sDate: this.$date.getMonthlyDate('', '-').start
					, eDate: this.$date.getToday('-')
				}
				this.getData()
			}
			, setMonth: function(input){
				let now = this.$date.getToday('-')
				let d = this.$date.getPrevMonth(now, input).fullDate
				let t = this.$date.getMonthlyDate(d, '-')
				this.item_search.sDate = t.start
				this.item_search.eDate = t.end
				this.getData()
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			// this.getData()
			this.$router.push({ name: 'StatisticsMulti'})
		}
	}
</script>
